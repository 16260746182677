/**
 * File:            OpenedMedia.js
 * Project:         Mediatheek
 * Author:          Dylan Koster
 * Date created:    Feb 04, 2024
 *
 * Description:
 * Overlay for displaying Media when it is opened.
 */

import { useEffect } from "react";
import { deleteFile } from "../FileManager";

/**
 * General overlay for displayed opened Media. Darkens background and displays a enlarged version of the media.
 *
 * @param {int} openedMedia The index of the media that should be opened.
 * @param {function} setOpenedMedia The function that alters the openedMedia state.
 * @param {array} mediaList The list in which the Media are stored.
 * @param {function} setMediaList The function that alters the openedMedia state.
 * @param {array} order The order in which the Media are displayed on the page.
 */
export function OpenedMedia({ openedMedia, setOpenedMedia, mediaList, setMediaList, order }) {
    let index = order[openedMedia];

    // Hide overlay based on if the source is set or not.
    let disp = openedMedia !== -1 ? "" : " d-none";
    if (disp === "") {
        document.body.classList.add("overflow-hidden");
    } else {
        document.body.classList.remove("overflow-hidden");
    }

    useEffect(() => {
        if (disp === "") {
            document.onkeydown = (event) => {
                handleKeyDown(openedMedia, setOpenedMedia, mediaList.length, event);
            };
        } else {
            document.onkeydown = null;
        }
    }, [disp, openedMedia, setOpenedMedia, mediaList.length]);

    if (!mediaList[index]) return;

    let file = mediaList[index];

    let elm;
    if (file["type"].startsWith("image")) {
        elm = <img className="h-100 w-100 object-fit-contain" src={file["content"]} alt="" />;
    } else if (file["type"].startsWith("video")) {
        elm = (
            <video controls src={file["content"]} className="h-100 object-fit-contain" style={{ maxWidth: "100%" }} />
        );
    }

    return (
        <div
            className={
                "openedmedia position-fixed top-0 start-0 d-flex flex-column w-100 h-100 bg-secondary overflow-hidden user-select-none " +
                disp
            }
            style={{ "--bs-bg-opacity": 0.5 }}>
            <div className="d-flex w-100 my-1 px-2 align-items-center text-center">
                <div
                    className="ml-auto"
                    onClick={() => {
                        setOpenedMedia(-1);
                        deleteFile(mediaList[index] ? mediaList[index]["id"] : "", mediaList).then((index) => {
                            setMediaList((list) => list.toSpliced(index, 1));
                        });
                    }}>
                    <i className="bi bi-trash-fill h1" fill="white"></i>
                </div>
                <h1 className="flex-fill">{mediaList[index] ? mediaList[index]["name"] : ""}</h1>
                <div
                    className="ml-auto"
                    onClick={() => {
                        setOpenedMedia(-1);
                    }}>
                    <i className="bi bi-x-circle-fill h1" fill="white"></i>
                </div>
            </div>

            <div className="d-flex flex-fill p-2 overflow-auto justify-content-center align-items-center">
                <div
                    className={`z-3 mr-auto ${index === 0 ? "bi-none" : ""}`}
                    onClick={() => {
                        setOpenedMedia(openedMedia - 1);
                    }}>
                    <i className="bi bi-arrow-left-circle-fill h1" fill="white"></i>
                </div>
                <div id="opened-media" className="d-flex flex-grow-1 justify-content-center h-100">
                    {elm}
                </div>

                <div
                    className={`z-3 ml-auto ${index === mediaList.length - 1 ? "bi-none" : ""}`}
                    onClick={() => {
                        setOpenedMedia(openedMedia + 1);
                    }}>
                    <i className="bi bi-arrow-right-circle-fill h1" fill="white"></i>
                </div>
            </div>
        </div>
    );
}

/**
 * Handles keydown events when the openedmedia is opened. This listens to three keys being pressed:
 *  - Escape closes the openedmedia.
 *  - ArrowLeft opens the previous media (openedmedia - 1)
 *  - ArrowRight opens the next media (openedmedia + 1)
 *
 * @param {int} openedMedia The index of the current openedmedia.
 * @param {function} setOpenedMedia Function that alters the openedmedia state.
 * @param {int} listLength The length of the medialist, used to determine if the current openedmedia is the last.
 * @param {Event} event The event provided by the listener.
 */
function handleKeyDown(openedMedia, setOpenedMedia, listLength, event) {
    switch (event.key) {
        case "ArrowLeft":
            if (openedMedia <= 0) return;
            setOpenedMedia(openedMedia - 1);
            break;
        case "ArrowRight":
            if (openedMedia >= listLength - 1) return;
            setOpenedMedia(openedMedia + 1);
            break;
        case "Escape":
            setOpenedMedia(-1);
            break;
        default:
            break;
    }
}

/**
 * File:            Header.js
 * Project:         Mediatheek
 * Author:          Dylan Koster
 * Date created:    Feb 04, 2024
 *
 * Description:
 * Header component, contains the name, navigation buttons and authorization buttons.
 */

import { Link } from "react-router-dom";
import { useState } from "react";

import { folderDictionary } from "../exportVars.js";
import { AuthButton } from "../auth.js";

/**
 * Main header object, a sticky div with three segments, information (name and logo), navigation buttons for all pages,
 * and the authorization and logout buttons.
 *
 * @param {string} accessToken The token with which data can be gathered from the Google API.
 * @param {function} setAccessToken The function with which the accessToken state can be altered.
 * @param {Object} userInfo An object with various information string about the logged user.
 */
export function Header({ accessToken, setAccessToken, userInfo }) {
    let authorized = !!accessToken;

    let folderPageLinkList = [];
    for (const [key, val] of Object.entries(folderDictionary)) {
        const elm = <PageLink name={key} key={val} href={"/page?id=" + val} authorized={authorized} />;
        folderPageLinkList.push(elm);
    }

    return (
        <nav className="navbar py-0 navbar-expand-lg navbar-light border-bottom bg-light" id="header">
            <div className="container-fluid h-100">
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#mediatheekNavbarToggler"
                    data-bs-auto-close="false"
                    aria-controls="mediatheekNavbarToggler"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="h-100">
                    <img className="h-100" src={process.env.PUBLIC_URL + "slurpvis.webp"} alt="Slurpvissen logo"></img>
                </div>
                <div className="z-3 p-2 collapse bg-light navbar-collapse" id="mediatheekNavbarToggler">
                    <ul className="navbar-nav me-auto justify-content-center mb-md-0">
                        {folderPageLinkList}
                        <PageLink name="Random" href="/random" authorized={authorized} />
                        <PageLink name="Upload" href="/upload" authorized={authorized} />
                    </ul>
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                            {authorized ? `Authorized as: ${userInfo.displayName}` : "Not logged in."}
                        </div>
                        <div className="ms-3">
                            <AuthButton name="Authorize" auth={true} accessToken={accessToken} />
                            <AuthButton
                                name="Logout"
                                auth={false}
                                accessToken={accessToken}
                                setAccessToken={setAccessToken}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

/**
 * Link that directs the user to each of the different pages.
 *
 * @param {string} name The name that should be displayed as the Link.
 * @param {string} href The target location to link to.
 * @param {boolean} authorized Whether the user is authorized by the API.
 */
export function PageLink({ name, href, authorized = true }) {
    const [err, setErr] = useState(false);
    let classes = authorized ? "" : " disabled";
    classes += err ? " err" : "";

    return (
        <li
            id={`link-${name}`}
            onClick={() => {
                if (!authorized) setErr(true);
            }}
            onAnimationEnd={() => {
                setErr(false);
            }}
            className={`nav-item ${classes}`}>
            <Link to={authorized ? href : ""} className="nav-link px-2">
                <span data-bs-toggle="collapse" data-bs-target="#mediatheekNavbarToggler">
                    {name}
                </span>
            </Link>
        </li>
    );
}

export const folderDictionary = {
    Main: process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_ID,
    Dylan: process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_DYLAN_ID,
    Ivar: process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_IVAR_ID,
    Mark: process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_MARK_ID,
    Martijn: process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_MARTIJN_ID,
    Nick: process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_NICK_ID,
    Richard: process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_RICHARD_ID,
    Sam: process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_SAM_ID,
    Troy: process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_TROY_ID,
    Tjappies: process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_TJAPPIES_ID,
};

/**
 * Split mix pseudo-random number generation algorithm. Returns a number from 0 to 1.
 * Function taken from https://stackoverflow.com/questions/521295/seeding-the-random-number-generator-in-javascript.
 *
 * @param {int} a The random seed
 * @returns A pseudorandom 32-bit integer.
 */
export function splitmix32(a) {
    a |= 0;
    a = (a + 0x9e3779b9) | 0;
    let t = (a ^ a) >>> 16;
    t = Math.imul(t, 0x21f0aaad);
    t = (t ^ t) >>> 15;
    t = Math.imul(t, 0x735a2d97);
    return ((t = (t ^ t) >>> 15) >>> 0) / 4294967296;
}

/**
 * File:            Media.js
 * Project:         Mediatheek
 * Author:          Dylan Koster
 * Date created:    Feb 04, 2024
 *
 * Description:
 * Media components, contains image (either full image or thumbnail) in a box with the name and filetype above. RawMedia
 * can play video in the media card, while Media can only play videos in OpenedMedia.
 */

/**
 * Media component, shows a thumbnail of the published media, either the full image or a part of the video. These can be
 * clicked to open the media in full.
 *
 * @param {MediatheekFile} file The Mediatheekfile object for the file that is opened.
 * @param {int} colsize The amount of columns that this card should take up.
 * @param {function} setOpenedMedia The function that alters the openedMedia state.
 * @param {string} className Any additional classes to be added to the Media.
 */
export function Media({ file, colsize, setOpenedMedia, className = "" }) {
    if (!file) return;

    let img = (
        <img
            src={file["thumbnail"]}
            className="object-fit-contain px-1 h-100 align-middle card-img-top"
            alt={file["name"]}
        />
    );
    let colClass = typeof colsize !== "undefined" && colsize !== 0 ? "col-" + colsize : "";

    return (
        <div
            id={file["id"]}
            className={`${colClass} px-0 card mb-1 user-select-none ${className}`}
            onClick={setOpenedMedia}>
            <div className="row ml-1 card-header">
                <div className="col-6">{file["name"].split(".")[0]}</div>
                <div className="col-6 header-type">{file["name"].split(".")[1]}</div>
            </div>
            {img}
        </div>
    );
}

/**
 * RawMedia component, shows either the full image or the video, which can be played without opening it.
 *
 * @param {MediatheekFile} file The Mediatheekfile object for the file that is opened.
 */
export function RawMedia({ file }) {
    if (!file) return;

    let comp;
    let classes = "object-fit-contain px-1 h-100 align-middle card-img-top";
    if (file["type"].startsWith("image/")) {
        comp = <img src={file["thumbnail"]} className={classes} alt={file["name"]} />;
    } else if (file["type"].startsWith("video/")) {
        comp = <video id="vid" controls src={file["content"]} className={classes} alt={file["name"]} />;
    } else {
        return;
    }

    return (
        <div id={file["id"]} className={`px-0 card mb-1 user-select-none flex-grow-1 flex-lg-grow-0 min-h-0 h-95`}>
            <div className="row ml-1 card-header">
                <div className="col-6">{file["name"].split(".")[0]}</div>
                <div className="col-6 header-type">{file["name"].split(".")[1]}</div>
            </div>
            {comp}
        </div>
    );
}

/**
 * File:            Upload.js
 * Project:         Mediatheek
 * Author:          Dylan Koster
 * Date created:    May 09, 2024
 *
 * Description:
 * Component for uploading files to the Mediatheek via an easier route than Google drive.
 */

import { useRef, useState } from "react";
import { uploadMultipart, uploadResumable } from "../FileManager";
import { folderDictionary } from "../exportVars";

export const UploadStates = {
    DEFAULT: 0,
    UPLOADING: 1,
    SUCCESS: 2,
    ERROR: 3,
};

/**
 * Upload component, creates a page that allows for easy uploading of image and video files to the Mediatheek.
 *
 * @param accessToken The access token provided by Google that can be used to call the API.
 */
export function Upload({ accessToken }) {
    let authorized = !!accessToken;

    let [uploadState, setUploadState] = useState([]);
    let filesRef = useRef(null);
    let [files, setFiles] = useState([]);
    let [curFolder, setCurFolder] = useState(process.env.REACT_APP_GOOGLE_DRIVE_MEDIATHEEK_ID);

    // Returns either a new map, or the existing map that maps files to DOM nodes.
    function getMap() {
        if (!filesRef.current) {
            filesRef.current = new Map();
        }

        return filesRef.current;
    }

    // Create a ChosenFile element for each selected file.
    let compList = [];
    files.forEach((file) => {
        let onClick = () => {
            let index = files.indexOf(file);
            setFiles(files.toSpliced(index, 1));
        };

        compList.push(
            <ChosenFile
                key={file["name"]}
                usedRef={(node) => {
                    const map = getMap();
                    if (node) {
                        map.set(file, node);
                    } else {
                        map.delete(file);
                    }
                }}
                uploadState={uploadState}
                delOnClick={onClick}
                file={file}
            />
        );
    });

    let statusElm = <></>;

    const curUploading = uploadState.filter((elm) => elm === UploadStates.UPLOADING).length;
    const curFinished = uploadState.filter((elm) => elm === UploadStates.SUCCESS).length;
    const curError = uploadState.filter((elm) => elm === UploadStates.ERROR).length;
    if (uploadState.length !== 0) {
        if (curUploading > 0) {
            statusElm = (
                <>
                    <div className="spinner-border m-1" role="status"></div>
                    <span className="ms-2 sr-only">
                        Uploading... {curFinished}/{uploadState.length} done.
                    </span>
                </>
            );
        } else if (curFinished === uploadState.length) {
            if (files.length > 0) setFiles([]);
            statusElm = (
                <>
                    <i className="bi-check-lg text-success" style={{ fontSize: "2.5rem" }}></i>
                    <span className="ms-2 sr-only">Success! Uploaded {curFinished} files.</span>
                </>
            );
        } else {
            statusElm = (
                <>
                    <i className="bi-x-lg text-danger" style={{ fontSize: "2.5rem" }}></i>
                    <span className="ms-2 sr-only">Error uploading {curError} files, try again!</span>
                </>
            );
        }
    }

    let folderOptionList = [];
    for (const [key, val] of Object.entries(folderDictionary)) {
        const elm = (
            <option value={val} key={val}>
                {key}
            </option>
        );
        folderOptionList.push(elm);
    }

    return (
        <div className="d-flex justify-content-center mh-mp my-5 align-items-center flex-fill">
            <form className="d-flex flex-column w-75 p-2 g-3 mh-100 border p-2">
                <div className="d-flex mb-3 justify-content-center">
                    <input
                        id="file"
                        className="d-none"
                        type="file"
                        accept="image/*,video/*"
                        onChange={(e) => onFilesChange(e, files, setFiles)}
                        multiple
                    />
                    <label htmlFor="file" className="btn btn-primary d-block w-100 h-25">
                        Choose file(s)
                    </label>
                </div>
                <p className="text-center mb-0">Chosen files:</p>
                <div className="d-flex flex-column overflow-y-scroll mh-100 border">
                    {compList.length === 0 ? "No files chosen." : compList}
                </div>
                <div className="d-flex w-100 mt-3">
                    <label htmlFor="folder">To folder:</label>
                    <select
                        id="folder"
                        onChange={(e) => {
                            setCurFolder(e.target.value);
                        }}
                        className="form-select flex-shrink-1">
                        {folderOptionList}
                    </select>
                </div>
                <div className="mt-3 w-100">
                    <button
                        type="button"
                        className="btn btn-success w-100"
                        onClick={() => {
                            setUploadState(Array(files.length).fill(UploadStates.UPLOADING));
                            upload(files, curFolder, filesRef, uploadState, setUploadState);
                        }}
                        disabled={files.length === 0 || !authorized}>
                        Upload
                    </button>
                </div>
                <div className="d-flex m-2 w-100 justify-content-center align-items-center">{statusElm}</div>
            </form>
        </div>
    );
}

/**
 * Uploads the files in file to folder in the google drive.
 *
 * @param {Array} files The list of files that should be uploaded.
 * @param {string} folder The ID of the folder to which the files should be uploaded.
 * @param {Map} refList The Map of refs that map the files to the input element with the (modified) name.
 * @param {Array} uploadState The array that contains the uploadstates of all current uploading jobs.
 * @param {function} setUploadState Function that alters the uploadState state, which informs the user about the upload
 *                                  process.
 */
function upload(files, folder, refList, uploadState, setUploadState = (state) => {}) {
    if (files.length === 0 || folder === "") return;

    // Put in loop to upload all files instead of just the first.
    for (const file of files) {
        const newName = refList.current.get(file).value;

        if (file.size >= 5_000_000) {
            uploadResumable(newName, file, folder)
                .then((file) => {
                    const fileIndex = files.indexOf(file);
                    setUploadState((uploadState) => {
                        return uploadState.map((c, i) => {
                            if (i === fileIndex) {
                                return UploadStates.SUCCESS;
                            } else {
                                return c;
                            }
                        });
                    });
                })
                .catch((file) => {
                    const fileIndex = files.indexOf(file);
                    setUploadState((uploadState) => {
                        return uploadState.map((c, i) => {
                            if (i === fileIndex) {
                                return UploadStates.ERROR;
                            } else {
                                return c;
                            }
                        });
                    });
                });
        } else {
            uploadMultipart(newName, file, folder)
                .then((file) => {
                    const fileIndex = files.indexOf(file);
                    setUploadState((uploadState) => {
                        return uploadState.map((c, i) => {
                            if (i === fileIndex) {
                                return UploadStates.SUCCESS;
                            } else {
                                return c;
                            }
                        });
                    });
                })
                .catch((file) => {
                    console.log(file);
                    const fileIndex = files.indexOf(file);
                    setUploadState((uploadState) => {
                        return uploadState.map((c, i) => {
                            if (i === fileIndex) {
                                return UploadStates.ERROR;
                            } else {
                                return c;
                            }
                        });
                    });
                });
        }
    }
}

/**
 * Event listener for when the selected files in the file input change, this updates the file list.
 *
 * @param {SyntheticEvent} e The event which contains all information.
 * @param {function} setFiles The function that alters the file list state.
 */
function onFilesChange(e, files, setFiles) {
    let newFiles = [];

    for (const file of e.target.files) {
        if (fileListContains(files, file)) continue;
        newFiles.push(file);
    }

    setFiles((a) => a.concat(newFiles));
}

/**
 * Check if the fileList contains the specified file. This uses the compareFiles function, which doesn't compare
 * content.
 *
 * @param {Array} fileList The list of file in which should be searched for file.
 * @param {File} file The file for which should be searched.
 * @returns True if file is found in fileList, false otherwise.
 */
function fileListContains(fileList, file) {
    for (const elm of fileList) {
        if (compareFiles(elm, file)) return true;
    }

    return false;
}
/**
 * Compare two File objects. Comparing by content is expensive so this function assumes that two files are equal if they
 * have the same name, size, mimetype, and modified date.
 *
 * @param {File} file1 File to which file2 should be compared.
 * @param {File} file2 File to which file1 should be compared.
 * @returns True if the two files have the same name, size, mimetype, and the last modified date.
 */
function compareFiles(file1, file2) {
    return (
        file1["name"] === file2["name"] &&
        file1["lastModified"] === file2["lastModified"] &&
        file1["size"] === file2["size"] &&
        file1["type"] === file2["type"]
    );
}

/**
 * Wrapper display for each selected file in the input box. This shows the name and a button which delets the file from
 * the selected list.
 *
 * @param {File} file The file for which the element is contructed.
 * @param {ref} usedRef The reference to the filename element.
 * @param {function} delOnClick The function which should be called when the trash symbol is clicked.
 */
function ChosenFile({ file, usedRef, delOnClick }) {
    const orgNameList = file["name"].split(".");
    const orgName = orgNameList.toSpliced(-1, 1).join(".");
    const fileType = orgNameList[orgNameList.length - 1];

    return (
        <div className="d-flex border p-2 m-1 flex-row align-items-center cursor-pointer">
            <i className="bi bi-file-earmark file-icon mb-0" fill="black"></i>
            <input type="text" ref={usedRef} className="ms-3 flex-fill filename" defaultValue={orgName} />
            <div>{fileType}</div>
            <div className="vr mx-3"></div>
            <i className="bi bi-trash-fill file-icon mb-0 btn-del" onClick={delOnClick} fill="black"></i>
        </div>
    );
}

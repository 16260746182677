/**
 * File:            Mediatheek.js
 * Project:         Mediatheek
 * Author:          Dylan Koster
 * Date created:    Feb 04, 2024
 *
 * Description:
 * Mediatheek component, throws together the Header and the Media components for the individual folder pages.
 */
import { useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import { OpenedMedia } from "./OpenedMedia.js";
import { get_file_data, get_files } from "../FileManager.js";

import { createGrid } from "../imagelayout/columnFill.js";

// Breakpoint values
const SM_BP = 768;
const MD_BP = 992;
const LG_BP = 1200;
const XL_BP = 1400;

// Column numbers per breapoint.
const SM_NUM_COLS = 2;
const MD_NUM_COLS = 3;
const LG_NUM_COLS = 4;
const XL_NUM_COLS = 5;
const XXL_NUM_COLS = 6;

/**
 * Mediatheek component, throws together the Header and the Media components for the individual folder pages.
 *
 * @param {string} accessToken The token with which data from the Google API is retrieved.
 */
export function Mediatheek({ accessToken }) {
    const [numCols, setNumCols] = useState(4);

    // Update the number of media columns based on the current width of the screen.
    let checkScreenWidth = function (setNumCols, numCols) {
        let width = window.innerWidth;
        if (width < SM_BP) {
            if (numCols !== SM_NUM_COLS) setNumCols(SM_NUM_COLS);
        } else if (width <= MD_BP) {
            if (numCols !== MD_NUM_COLS) setNumCols(MD_NUM_COLS);
        } else if (width <= LG_BP) {
            if (numCols !== LG_NUM_COLS) setNumCols(LG_NUM_COLS);
        } else if (width <= XL_BP) {
            if (numCols !== XL_NUM_COLS) setNumCols(XL_NUM_COLS);
        } else {
            if (numCols !== XXL_NUM_COLS) setNumCols(XXL_NUM_COLS);
        }
    };

    checkScreenWidth(setNumCols, numCols);
    window.addEventListener("resize", () => checkScreenWidth(setNumCols, numCols));

    const idRef = useRef();

    const [mediaList, setMediaList] = useState([]);
    const [folderId, setFolderId] = useState("");
    const [openedMedia, setOpenedMedia] = useState(-1);

    const [queryParameters] = useSearchParams();
    const tempFolderId = queryParameters.get("id");

    // Generate list of Media.
    let err = false;
    if (accessToken && !!tempFolderId && tempFolderId !== folderId) {
        setMediaList([]);
        setFolderId(tempFolderId);
        idRef.tempFolderId = tempFolderId;

        // Put all listed files into a json object.
        get_files(tempFolderId)
            .then(function (filesJson) {
                for (let fileIndex in filesJson) {
                    let file = filesJson[fileIndex];

                    get_file_data(file["id"])
                        .then(function (data) {
                            // Only post to mediaList if the folder is correct.
                            if (idRef.tempFolderId === data["folder"]) {
                                setMediaList((mediaList) => [...mediaList, data]);
                            }
                        })
                        .catch(function (e) {
                            // TODO: Proper error handling.
                            console.error(e);
                        });
                }
            })
            .catch(function (e) {
                console.error(e);
                err = true;
            });
    }

    if (err) return;

    // Generate order using the columnFill algorithm.
    let compList, order;
    if (mediaList) {
        [compList, order] = createGrid(
            mediaList.map((x) => x),
            setOpenedMedia,
            numCols
        );
    }

    return (
        <>
            <div id="mediaList" className="container">
                <div className="row">{compList}</div>
            </div>

            <div id="openedMedia">
                <OpenedMedia
                    openedMedia={openedMedia}
                    setOpenedMedia={setOpenedMedia}
                    mediaList={mediaList}
                    setMediaList={setMediaList}
                    order={order}
                />
            </div>
        </>
    );
}

/**
 * File:            Home.js
 * Project:         Mediatheek
 * Author:          Dylan Koster
 * Date created:    Apr 15, 2024
 *
 * Description:
 * Home page component, contains general information and a welcome note to users.
 */
import { AuthButton } from "../auth";

/**
 * Home component, redirects to HomeLoggedOut or HomeLoggedIn based on the authorization state.
 *
 * @param {Object} userInfo The information of the logged in Google account.
 * @param {string} accessToken Token with which authorization can be determined.
 * @param {function} setAccessToken Function with which the access token state can be altered.
 * @param {function} setUserInfo Function with which the user info state can be altered.
 */
export function Home({ userInfo, accessToken, setAccessToken, setUserInfo }) {
    let out = <></>;
    if (typeof userInfo === "undefined" || Object.keys(userInfo).length === 0) {
        out = <HomeLoggedOut accessToken={accessToken} setAccessToken={setAccessToken} setUserInfo={setUserInfo} />;
    } else {
        out = <HomeLoggedIn userInfo={userInfo} accessToken={accessToken} setAccessToken={setAccessToken} />;
    }

    return (
        <div className="h-100 d-flex align-items-center">
            <div className="w-100 text-center">{out}</div>
        </div>
    );
}

/**
 * Home screen component for logged in users, provides an enlarged logout button and basic information on how to use the
 * Mediatheek.
 *
 * @param {Object} userInfo The information of the logged in Google account.
 * @param {string} accessToken Token with which authorization can be determined.
 * @param {function} setAccessToken Function with which the access token state can be altered.
 */
function HomeLoggedIn({ userInfo, accessToken, setAccessToken }) {
    return (
        <>
            <div className="h1">Welcome to the Mediatheek,</div>
            <div className="h1">{userInfo.displayName + "!"}</div>
            <div className="p">Click the links above to open any folder.</div>

            <div className="mt-5">
                <AuthButton
                    className="bigAuth"
                    name="Logout"
                    auth={false}
                    accessToken={accessToken}
                    setAccessToken={setAccessToken}
                />
            </div>
        </>
    );
}

/**
 * Home screen component for logged out users, provides an enlarged authorization button and basic information on how to
 * use the Mediatheek.
 *
 * @param {string} accessToken Token with which authorization can be determined.
 */
function HomeLoggedOut({ accessToken }) {
    return (
        <>
            <div className="h1">Welcome to the Mediatheek!</div>
            <div className="h1">Log in using Google to gain access.</div>
            <div className="mt-5">
                <AuthButton className="bigAuth" name="Authorize" auth={true} accessToken={accessToken} />
            </div>
        </>
    );
}

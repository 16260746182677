/**
 * File:            index.js
 * Project:         Mediatheek
 * Author:          Dylan Koster
 * Date created:    Jan 28, 2023
 *
 * Description:
 * The main application file for the Mediatheek. This file contains the render function call and the encompassing App
 * component.
 */
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

import { React, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createRoot } from "react-dom/client";

import { loadCallback } from "./auth.js";

import { FileManager } from "./FileManager.js";
import { Mediatheek } from "./comps/Mediatheek.js";
import { Random } from "./comps/Random.js";
import { Header } from "./comps/Header.js";
import { Home } from "./comps/Home.js";
import { Upload } from "./comps/Upload.js";
import { AuthHandler } from "./auth.js";

/**
 * Main component of the Mediatheek. Contains a FileManager instance, a Header and the Routes of the list pages and
 * random page.
 */
function App() {
    const [accessToken, setAccessToken] = useState("");
    const [userInfo, setUserInfo] = useState({});

    window.addEventListener("load", () => loadCallback(setAccessToken));
    return (
        <>
            <FileManager accessToken={accessToken} setUserInfo={setUserInfo} />
            <Header accessToken={accessToken} setAccessToken={setAccessToken} userInfo={userInfo} />
            <Routes>
                <Route
                    path="/"
                    element={
                        <Home accessToken={accessToken} userInfo={userInfo} setAccessToken={setAccessToken} />
                    }></Route>
                <Route path="/page" element={<Mediatheek accessToken={accessToken} />}></Route>
                <Route path="/random" element={<Random accessToken={accessToken} />}></Route>
                <Route path="/auth" element={<AuthHandler setAccessToken={setAccessToken} />}></Route>
                <Route path="/upload" element={<Upload accessToken={accessToken} />}></Route>
            </Routes>
        </>
    );
}

const root = createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

/**
 * File:            columnFill.js
 * Project:         Mediatheek
 * Author:          Dylan Koster
 * Date created:    Apr 8, 2024
 *
 * Description:
 * This file describes an algorithm for displaying mixed-aspect ratio images efficiently on a page of n columns.
 */

import { Media } from "../comps/Media.js";
import { splitmix32 } from "./random.js";

// Seed for pseudo-random number generation.
let originalSeed = Date.now() * Math.random();

/**
 * Function for creating a grid of images stored in mediaList. noColumns is the amount of columns in which the pictures
 * should be placed.
 *
 * @param {*} mediaList The list of media that should be displayed.
 * @param {*} setOpenedMedia The function that changes the openedMedia state when a user clicks on an image.
 * @param {*} noColumns The amount of columns in which the media should be placed.
 */
export function createGrid(mediaList, setOpenedMedia, noColumns = 3) {
    // Store the aspect ratios of all media.
    let arlist = Array(mediaList.length).fill(0);
    for (let index in mediaList) {
        let media = mediaList[index];
        let ar = Math.ceil((media["width"] * 1.0) / media["height"]);
        arlist[index] = ar;
    }

    // Order the media and create a component list of them.
    let orderList = order(arlist, noColumns);
    let compList = [];
    let cumIndex = 0;
    let curComp = [];
    for (let i = 0; i < orderList.length; i++) {
        let index = orderList[i];
        let media = mediaList[index];
        let ar = Math.ceil((media["width"] * 1.0) / media["height"]);

        let setMedia = (function (a) {
            return function () {
                setOpenedMedia(a);
            };
        })(i);

        // Create a new component and add it to the current row.
        curComp.push(
            <Media
                key={Math.random()}
                colsize={ar * Math.floor(12 / noColumns)}
                file={media}
                setOpenedMedia={setMedia}></Media>
        );

        // When row is filled, wrap components in a row div and reset.
        if ((cumIndex += ar) % noColumns === 0) {
            compList.push(
                <div key={"col-sep-" + Math.random()} className="row">
                    {curComp}
                </div>
            );
            curComp = [];
        }
    }

    // Fill the last row with empty divs to match the aspect ratio to the amount of columns filled up.
    curComp.push(fillCol(cumIndex, noColumns));
    compList.push(
        <div key={"col-sep-" + Math.random()} className="row">
            {curComp}
        </div>
    );

    return [compList, orderList];
}

/**
 * Create a index list for the media in which the columns are filled with the appropriate aspect ratio of images.
 * E.g. When noColumns=3, a columns can have three media of aspect ratio 1.
 *
 * @param {*} arList The list of aspect ratios of the media.
 * @param {*} noColumns The amount of columns in which the media should fit.
 */
function order(arList, noColumns) {
    let order = [];
    let rowRoomLeft = noColumns;
    let arListCopy = arList.map((x) => x);
    let seed = originalSeed;

    // Create an array of indices of media that still fit in the current row.
    let available = [...Array(arListCopy.length).keys()].filter(
        (value) => arListCopy[value] >= 0 && arListCopy[value] <= rowRoomLeft
    );

    // Randomly choose any available media and put it in the order array.
    while (available.length > 0) {
        let randIndex = available[Math.floor(splitmix32(seed++) * available.length)];
        order.push(randIndex);

        rowRoomLeft -= arListCopy[randIndex];
        arListCopy[randIndex] = -1;
        if (rowRoomLeft <= 0) {
            rowRoomLeft = noColumns;
        }

        let tempRowRoom = rowRoomLeft;
        // Reset the available array.
        available = [...Array(arListCopy.length).keys()].filter(
            (value) => arListCopy[value] >= 0 && arListCopy[value] <= tempRowRoom
        );
    }

    return order;
}

/**
 * Fills a row in a list with as many elements needed to fill noColumns columns, when already having cumIndex items.
 * Creates noColumns-(cumIndex % noColumns) empty divs.
 *
 * e.g. With noColumns = 3, cumIndex = 7, two items are needed to fill the row to the next multiple of 3, 9.
 *
 * @param {int} cumIndex The amount of current entries in the row.
 * @param {int} noColumns The amount of columns that need to be filled.
 * @returns A list of noColumns-(cumIndex % noColumns) empty divs,
 */
function fillCol(cumIndex, noColumns) {
    let list = [];

    const emptyDivs = noColumns - (cumIndex % noColumns);
    for (let i = 0; emptyDivs !== noColumns && i < emptyDivs; i++) {
        list.push(<div key={"empty-div-" + Math.random()} className="col"></div>);
    }

    return list;
}
